<template>
    <b-row v-if="products && products.data">
        <b-col class="product-item mt-4" sm="6" md="4" v-for="(item, index) in products.data" :key="index">
            <product-item-component :product="item" @open="openTshirt" />
        </b-col>

        <product-item-modal-component :product="product" />
    </b-row>
</template>

<script>
import ProductItemComponent from "./ProductItem";
import ProductItemModalComponent from "@/components/ProductItemModal";

export default {
    props: {
        products: {
            required: true,
            default: false
        },
    },
    data() {
        return {
            product: null,
        };
    },
    methods: {
        openTshirt(product) {
            this.product = product;
            this.$root.$emit("bv::show::modal", "product-item-modal");
        },
    },
    components: {
        ProductItemComponent,
        ProductItemModalComponent,
    },
};
</script>
