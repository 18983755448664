<template>
    <b-container>
        <b-row>
            <b-col v-if="category == 'cursos'" cols="12" class="p-0 m-0">
                <img src="@/images/backgrounds/header-cursos.png"
                    class="img-fluid d-none d-md-block d-lg-block d-xl-block" />
                <img src="@/images/backgrounds/mobile/header-cursos.png" class="img-fluid d-block d-md-none rounded mb-3" />
            </b-col>
            <b-col v-else-if="category == 'fardamento'" cols="12" class="p-0 m-0">
                <img src="@/images/backgrounds/header-fardamento.png"
                    class="img-fluid d-none d-md-block d-lg-block d-xl-block" />
                <img src="@/images/backgrounds/mobile/header-fardamento.png" class="img-fluid d-block d-md-none rounded mb-3" />
            </b-col>
            <b-col v-else-if="category == 'abadas'" cols="12" class="p-0 m-0">
                <img src="@/images/backgrounds/header-abadas.png"
                    class="img-fluid d-none d-md-block d-lg-block d-xl-block" />
                <img src="@/images/backgrounds/mobile/header-abadas.png" class="img-fluid d-block d-md-none rounded mb-3" />
            </b-col>
            <b-col v-else-if="category == 'religioso'" cols="12" class="p-0 m-0">
                <img src="@/images/backgrounds/header-religioso.png"
                    class="img-fluid d-none d-md-block d-lg-block d-xl-block" />
                <img src="@/images/backgrounds/mobile/header-religioso.png" class="img-fluid d-block d-md-none rounded mb-3" />
            </b-col>
            <b-col v-else cols="12" class="p-0 m-0">
                <router-link to="/simulador">
                    <img src="@/images/backgrounds/header-esportivo.png"
                        class="img-fluid d-none d-md-block d-lg-block d-xl-block" />
                    <img src="@/images/backgrounds/mobile/header-esportivo.png" class="img-fluid d-block d-md-none rounded mb-3" />
                </router-link>
            </b-col>
        </b-row>
    </b-container>
</template>

<script>
export default {
    props: {
        category: {
            required: false,
            default: false,
        }
    }
}
</script>
