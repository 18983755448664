<template>
    <div class="product-item" v-lazy-container="{ selector: 'img' }" @click.prevent="open(product)">
        <img class="img-fluid product-image rounded" :data-src="product.link" />
        <span class="loading">
            <b-skeleton-img class="img-loading" no-aspect height="395px" />
        </span>
    </div>
</template>

<style>
.product-item {
    cursor: pointer;
}

img:not([lazy='loading'])+.loading {
    display: none;
}
</style>

<script>
export default {
    props: {
        product: {
            required: true
        },
    },
    methods: {
        open(product) {
            this.$emit("open", product);
        }
    }
}
</script>
