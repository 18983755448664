<template>
    <div>
        <header-component />
        <product-header :category="category" />

        <b-container class="content-page bg-secondary rounded pt-3" v-if="loading">
            <LoadingProducts />
        </b-container>

        <b-container class="content-page bg-secondary rounded" v-else>
            <div v-if="data.data && data.data.length >= 1">
                <div class="overflow-auto pt-4">
                    <b-pagination-nav pills :link-gen="linkGen" :number-of-pages="data ? data.last_page : 1"
                        align="right"></b-pagination-nav>
                </div>
                <product-list-component :products="data" />
                <div class="overflow-auto mt-4 pb-3">
                    <b-pagination-nav pills :link-gen="linkGen" :number-of-pages="data ? data.last_page : 1"
                        align="right"></b-pagination-nav>
                </div>
            </div>
            <div class="pt-5 pb-5 text-center" v-else>
                <b-alert variant="warning" show>
                    Nenhum produto encontrado para esta página
                </b-alert>
            </div>
        </b-container>

        <footer-component />
    </div>
</template>

<style>

</style>

<script>
import api from "../Api";

import HeaderComponent from "@/components/Header"
import FooterComponent from "@/components/Footer"
import ProductListComponent from "@/components/ProductList"
import LoadingProducts from "@/components/LoadingProducts"
import ProductHeader from "@/components/ProductHeader"

export default {
    props: {
        category: {
            required: false,
            default: false
        },
    },
    data() {
        return {
            title: "Produtos",
            data: [],
            page: 1,
            loading: true,
        }
    },
    created() {
        this.page = this.$route.query?.page ? this.$route.query.page : 1;
        this.getTshirts();
    },
    methods: {
        getTshirts() {
            this.loading = true;
            api.post(`/portfolio?category=${this.category}&page=${this.page}`)
                .then((res) => {
                    this.loading = false;
                    this.data = res.data;
                }).catch((error) => {
                    console.log(error);
                    this.data = [];
                    this.loading = false;
                });
        },
        linkGen(page) {
            return page === 1 ? '?' : `?page=${page}`
        }
    },
    components: {
        HeaderComponent,
        FooterComponent,
        ProductListComponent,
        LoadingProducts,
        ProductHeader
    }
}
</script>
