<template>
    <b-modal id="product-item-modal" :title="product ? product.label : ''" hide-footer>
        <div v-if="product">
            <ProductItem :product="product" />
        </div>
    </b-modal>
</template>

<script>
import ProductItem from './ProductItem.vue';
export default {
    props: {
        product: {
            required: false,
            default: null
        }
    },
    components: {
        ProductItem
    }
}
</script>
